import React from "react";
import styled from "styled-components";

const Text: React.FC<{
  placeholder: string;
  width?: string | number | undefined;
  value: string;
  valueChanged: (newValue: string) => void;
}> = (props) => {
  const { placeholder, width, value, valueChanged } = props;
  return (
    <Input
      placeholder={placeholder}
      value={value}
      onChange={(changeEvent) => valueChanged(changeEvent.target.value)}
      style={{
        width,
      }}
    />
  );
};
export default Text;

const Input = styled.input.attrs({
  type: "text",
})`
  padding: 0.5rem;

  background-color: white;
  color: #656565;

  border: solid 1px #ebebeb;
  border-radius: 2px;
`;
