import React from "react";
import styled from "styled-components";

import { useAdlookTheme } from "hooks/theme";
import { Company, Creative as CreativeData } from "repo/CreativeData";

import { adlookTheme } from "../layout/theme";

const CreativesAdlookRedirectLink: React.FC<{
  creatives: CreativeData[];
}> = ({ creatives }) => {
  const [isAdlookTheme] = useAdlookTheme();

  if (!creatives.some((creative) => creative.company === Company.ADLOOK) || isAdlookTheme) {
    return null;
  }

  const url = `https://creatives-preview.adlook.com${window.location.pathname}${window.location.search}`;

  return <Link href={url}>Switch to Adlook preview</Link>;
};

export default CreativesAdlookRedirectLink;

const Link = styled.a`
  display: inline-block;
  padding: 1rem;
  margin-bottom: 0.5rem;

  cursor: pointer;

  border: none;
  border-radius: 1rem;

  background: ${adlookTheme.secondary};
  color: #fff;
`;
