import * as Sentry from "@sentry/react";
import config from "config";

export function init(options: Sentry.BrowserOptions): void {
  Sentry.init(options);
}

export function logError(error: unknown): void {
  Sentry.captureException(error);

  if (!config?.sentryEnabled) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function logWarning(message: string, context: Record<string, Record<string, unknown>> = {}): void {
  Sentry.withScope(function (scope) {
    for (const entry of Object.entries(context)) {
      scope.setContext(entry[0], entry[1]);
    }
    Sentry.captureMessage(message, { level: "warning" });
  });

  if (!config?.sentryEnabled) {
    // eslint-disable-next-line no-console
    console.log(`Warning: ${message}`);
  }
}

const errorLogger = { init, logError, logWarning };
export default errorLogger;
