import React from "react";
import styled from "styled-components";

const Chip: React.FC<{
  text: string;
  $enabled: boolean;
  enabledChanged: (newEnable: boolean) => void;
  title?: string;
}> = (props) => {
  const { text, $enabled, enabledChanged, title } = props;
  return (
    <Wrapper $enabled={$enabled} onClick={() => enabledChanged(!$enabled)} title={title}>
      {text}
    </Wrapper>
  );
};
export default Chip;

const Wrapper = styled.div<{
  $enabled: boolean;
}>`
  display: inline-block;
  padding: 0.5rem 0.75rem;

  border: solid 2px white;
  border-radius: 1rem;

  cursor: pointer;

  ${(props) =>
    props.$enabled
      ? `
      border-color: ${props.theme.chipBackgroundColor};
      background-color: ${props.theme.chipBackgroundColor};
      color: white;
    `
      : `
      border-color: #cccccc;
      background-color: white;
      color: #1f1f1f;
      `}
`;
