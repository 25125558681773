import React from "react";

import { Creative as CreativeData } from "repo/CreativeData";

import CreativePreview from "./CreativePreview";
import CreativePreviews from "./CreativePreviews";

export const VARIABLE_OFFERS_NUMBER = "Variable";

const Creative: React.FC<{
  creative: CreativeData;
  maxPreviews: number | null;
}> = (props) => {
  const { creative, maxPreviews } = props;

  return creative.previews.length === 1 ? (
    <CreativePreview
      hash={creative.hash}
      hashShow={true}
      width={creative.previews[0].width}
      height={creative.previews[0].height}
      previewUrl={creative.previews[0].previewUrl}
      details={{
        Status: <>{creative.status}</>,
        Size: (
          <>
            {creative.previews[0].width}x{creative.previews[0].height}
          </>
        ),
        "Offers number": <>{creative.previews[0].offersNumber ?? VARIABLE_OFFERS_NUMBER}</>,
      }}
    />
  ) : (
    <CreativePreviews
      hash={creative.hash}
      previews={creative.previews.map((preview) => ({
        width: preview.width,
        height: preview.height,
        previewUrl: preview.previewUrl,
        title: `${creative.hash} Preview`,
        details: {
          Size: (
            <>
              {preview.width}x{preview.height}
            </>
          ),
          "Offers number": <>{preview.offersNumber ?? VARIABLE_OFFERS_NUMBER}</>,
        },
      }))}
      details={{
        Status: <>{creative.status}</>,
      }}
      maxPreviews={maxPreviews}
    />
  );
};
export default Creative;
