import React from "react";
import { WaveLoading } from "styled-spinkit";

import { adlookTheme, rtbTheme } from "components/layout/theme";
import { useAdlookTheme } from "hooks/theme";

const Loader: React.FC = () => {
  const [isAdlookTheme] = useAdlookTheme();

  return <WaveLoading color={isAdlookTheme ? adlookTheme.secondary : rtbTheme.secondary} />;
};

export default Loader;
