import React from "react";
import styled from "styled-components";

import Chip from "./Chip";

export type Key = string | number | null;

export interface Option {
  key: Key;
  text: string;
  title?: string;
}

export const ChipsMultiple: React.FC<{
  options: Option[];
  keysEnabled: Key[];
  keysEnabledChanged: (keysEnabled: Key[]) => void;
}> = (props) => {
  const { options, keysEnabled, keysEnabledChanged } = props;
  const keysEnabledSet = new Set(keysEnabled);

  return (
    <ChipsContainer>
      {options.map((option) => (
        <ChipContainer key={option.key}>
          <Chip
            text={option.text}
            title={option.title}
            $enabled={keysEnabledSet.has(option.key)}
            enabledChanged={(enabled) => {
              const keysEnabledCloned = new Set(keysEnabledSet);
              if (enabled) {
                keysEnabledCloned.add(option.key);
              } else {
                keysEnabledCloned.delete(option.key);
              }
              keysEnabledChanged(Array.from(keysEnabledCloned));
            }}
          />
        </ChipContainer>
      ))}
    </ChipsContainer>
  );
};

export const ChipsSingle: React.FC<{
  options: Option[];
  keyEnabled: Key;
  keyEnabledChanged: (keyEnabled: Key) => void;
}> = (props) => {
  const { options, keyEnabled, keyEnabledChanged } = props;

  return (
    <ChipsContainer>
      {options.map((option) => (
        <ChipContainer key={option.key}>
          <Chip
            text={option.text}
            $enabled={option.key === keyEnabled}
            enabledChanged={() => {
              keyEnabledChanged(option.key);
            }}
          />
        </ChipContainer>
      ))}
    </ChipsContainer>
  );
};

const ChipsContainer = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -0.25rem;
`;
const ChipContainer = styled.div`
  margin: 0.25rem;
`;
