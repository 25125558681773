import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { useAdlookTheme } from "hooks/theme";

const TopBar: React.FC = () => {
  return (
    <Bar>
      <Logo />
    </Bar>
  );
};

export default TopBar;

const Logo: React.FC = () => {
  const [isAdlookTheme] = useAdlookTheme();

  if (isAdlookTheme) {
    return (
      <Link to="/">
        <TopBarLogoWrapper>
          <img src="/logo-adlook.svg?v2" alt="Adlook Logo" />
        </TopBarLogoWrapper>
      </Link>
    );
  }

  return (
    <Link to="/">
      <TopBarLogoWrapper>
        <img src="/logo-rtbhouse.png" alt="RTB House Logo" />
        <span>Creatives preview</span>
      </TopBarLogoWrapper>
    </Link>
  );
};

const Bar = styled.div`
  display: flex;
  align-items: center;
  padding: 1.25rem;
  background-color: ${(props) => props.theme.primary};
  border-bottom: solid 3px ${(props) => props.theme.secondary};
  color: white;
`;

const TopBarLogoWrapper = styled.div`
  text-align: right;
  > img {
    display: block;
  }
  > span {
    font-size: 0.75rem;
  }
`;
