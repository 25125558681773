import React from "react";
import styled from "styled-components";

import ClipboardButton from "./ClipboardButton";
import CreativePreview from "./CreativePreview";

const CreativePreviews: React.FC<{
  hash: string;
  previews: Array<{
    width: number;
    height: number;
    previewUrl: string;
    details?: { [key: string]: React.ReactNode };
  }>;
  details?: { [key: string]: React.ReactNode };
  maxPreviews: number | null;
}> = (props) => {
  const { hash, previews, details, maxPreviews } = props;

  const previewsLimited = maxPreviews !== null ? previews.slice(0, maxPreviews) : previews;
  const previewsHiddenCount = maxPreviews !== null ? Math.max(0, previews.length - maxPreviews) : 0;

  return (
    <Wrapper>
      <Details>
        <Detail>
          <Key>Hash: </Key>
          <Value>
            {hash} <ClipboardButton value={hash} />
          </Value>
        </Detail>
        {Object.entries(details || {}).map(([key, value]) => (
          <Detail key={key}>
            <Key>{key}: </Key>
            <Value>{value}</Value>
          </Detail>
        ))}
      </Details>
      <Previews>
        {previewsLimited.map((preview) => (
          <Preview key={preview.previewUrl}>
            <CreativePreview hash={hash} hashShow={false} {...preview} />
          </Preview>
        ))}
        {previewsHiddenCount > 0 ? (
          <PreviewsHiddenCount>
            {previewsHiddenCount} more previews were hidden because of filtering settings
          </PreviewsHiddenCount>
        ) : null}
      </Previews>
    </Wrapper>
  );
};
export default CreativePreviews;

const Wrapper = styled.div``;
const Details = styled.div`
  margin-bottom: 1rem;
  color: #656565;
`;
const Detail = styled.div`
  display: inline-block;
`;
const Key = styled.span`
  text-transform: uppercase;
  font-weight: bolder;
  padding-right: 0.25rem;
`;
const Value = styled.span`
  margin-right: 1rem;
`;
const Previews = styled.div``;
const Preview = styled.div`
  margin-bottom: 2rem;
  padding-left: 1rem;
  border-left: solid 3px #cccccc;

  :last-child {
    margin-bottom: 0;
  }
`;
const PreviewsHiddenCount = styled(Preview)`
  color: #656565;
`;
