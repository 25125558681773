import styled from "styled-components";

const Button = styled.button`
  display: inline-block;
  padding: 0.75rem 1rem;

  background-color: ${(props) => props.theme.secondary};
  color: white;

  border: none;
  border-radius: 2px;

  cursor: pointer;
`;
export default Button;
