import React from "react";
import styled from "styled-components";

import { useAdlookTheme } from "hooks/theme";

const Footer: React.FC = () => {
  const [isAdlookTheme] = useAdlookTheme();

  if (isAdlookTheme) {
    return (
      <Wrapper>
        <Left>
          <a href="https://www.adlook.com/" target="_blank">
            Adlook
          </a>
          <a href="https://www.adlook.com/privacy-policy" target="_blank">
            Privacy Policy
          </a>
          <a href="https://www.adlook.com/#contact" target="_blank">
            Contact
          </a>
        </Left>
        <Right>
          Copyright &copy; &nbsp;
          {new Date().getFullYear()}
          &nbsp; Adlook
        </Right>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Left>
        <a href="https://www.rtbhouse.com/" target="_blank">
          RTB House Corporate
        </a>
        <a href="https://www.rtbhouse.com/privacy/" target="_blank">
          Privacy Policy
        </a>
        <a href="https://www.rtbhouse.com/contact/" target="_blank">
          Contact
        </a>
      </Left>
      <Right>
        Copyright &copy; &nbsp;
        {new Date().getFullYear()}
        &nbsp; RTB House
      </Right>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div`
  display: flex;
  padding: 1rem;
  background-color: #6b6b6b;
  color: white;
  font-weight: bold;
  font-size: 0.75rem;
  text-transform: uppercase;
`;

const Left = styled.div`
  flex: auto;
  > * {
    padding: 0 0.5rem;
    border-left: solid 2px white;

    &:first-child {
      padding-left: 0;
      border-left: none;
    }
  }
`;

const Right = styled.div``;
