import React, { useState } from "react";
import styled from "styled-components";

import Text from "components/forms/Text";
import { CommonParameters, parseSizesString, serializeSizesString, Size } from "repo/CreativeData";

const CommonParametersSizesForm: React.FC<{
  commonParameters: CommonParameters;
  setCommonParameters: (commonParameters: CommonParameters) => void;
  sizes: Size[] | undefined;
  setSizes: (sizes: Size[] | undefined) => void;
}> = (props) => {
  const { commonParameters, setCommonParameters, sizes, setSizes } = props;

  const [subcampaignsString, setSubcampaignsString] = useState((commonParameters.subcampaigns || []).join(","));
  const setSubcampaignsFromString = (newSubcampaignsString: string) => {
    setSubcampaignsString(newSubcampaignsString);
    const subcampaigns = newSubcampaignsString
      .split(",")
      .map((subcampaign: string) => subcampaign.trim())
      .filter((subcampaign) => !!subcampaign);
    const newCommonParameters = {
      ...commonParameters,
      subcampaigns: subcampaigns.length ? subcampaigns : undefined,
    };
    setCommonParameters(newCommonParameters);
  };

  const [sizesString, setSizesString] = useState(sizes !== undefined ? serializeSizesString(sizes) : "");
  const setSizesFromString = (newSizesString: string) => {
    setSizesString(newSizesString);
    const sizes = newSizesString.length > 0 ? parseSizesString(newSizesString) : undefined;
    setSizes(sizes);
  };

  return (
    <Wrapper>
      <Key>?subcampaigns=</Key>
      <Text
        placeholder="(Optional) Subcampaign hashes, comma separated"
        width="30rem"
        value={subcampaignsString}
        valueChanged={setSubcampaignsFromString}
      />
      <Key>&amp;sizes=</Key>
      <Text
        placeholder="(Optional) Sizes in widthXheight format, comma separated"
        width="30rem"
        value={sizesString}
        valueChanged={setSizesFromString}
      />
    </Wrapper>
  );
};
export default CommonParametersSizesForm;

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  grid-auto-rows: 1fr;
  text-align: left;
`;
const Key = styled.pre`
  margin-right: 1rem;
`;
