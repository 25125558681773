import { useEffect } from "react";

import { useAdlookTheme } from "./theme";

export default function useDocumentTitle(title?: string | null): void {
  const [isAdlookTheme] = useAdlookTheme();
  const postfix = `${isAdlookTheme ? "Adlook" : "RTB House"} Creatives Preview`;

  useEffect(() => {
    document.title = title ? `${title} | ${postfix}` : postfix;
  }, [title, postfix]);
}
