import React from "react";
import styled from "styled-components";

const Textarea: React.FC<{
  rows: number;
  cols: number;
  placeholder: string;
  value: string;
  valueChanged: (newValue: string) => void;
}> = (props) => {
  const { rows, cols, placeholder, value, valueChanged } = props;
  return (
    <Input
      placeholder={placeholder}
      value={value}
      onChange={(changeEvent) => valueChanged(changeEvent.target.value)}
      rows={rows}
      cols={cols}
    />
  );
};
export default Textarea;

const Input = styled.textarea.attrs({})`
  padding: 0.5rem;

  background-color: white;
  color: #656565;

  border: solid 1px #ebebeb;
  border-radius: 2px;
`;
