import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styled from "styled-components";

const ClipboardButton: React.FC<{
  value: string;
}> = (props) => {
  const { value } = props;

  const [copied, setCopied] = useState(false);

  const doCopy = async () => {
    try {
      await navigator.clipboard.writeText(value);
      setCopied(true);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return (
    <Inner onClick={doCopy}>
      <FontAwesomeIcon icon={!copied ? faCopy : faCheck} />
    </Inner>
  );
};
export default ClipboardButton;

const Inner = styled.div`
  display: inline-block;

  width: 1em;
  margin-left: 0.25rem;

  cursor: pointer;
`;
