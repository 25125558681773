const colors = {
  rtb: {
    primary: "#432844",
    secondary: "#ef4138",
  },
  adlook: {
    primary: "#000",
    secondary: "#2ec970",
  },
};

export const rtbTheme = {
  ...colors.rtb,
  chipBackgroundColor: colors.rtb.primary,
  listViewBorderColor: colors.rtb.primary,
};

export const adlookTheme = {
  ...colors.adlook,
  chipBackgroundColor: colors.adlook.secondary,
  listViewBorderColor: colors.adlook.secondary,
};
