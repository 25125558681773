import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const PAGES_RANGE_LENGTH_DEFAULT = 5;

const PageChooser: React.FC<{
  page: number;
  setPage: (page: number) => void;
  pagesCount: number;
  pagesRangeLength?: number;
  description: React.ReactElement;
}> = (props) => {
  const { page, setPage, pagesCount, pagesRangeLength = PAGES_RANGE_LENGTH_DEFAULT, description } = props;

  // Calculate pages shortcut items
  const pagesRangeLengthHalf = Math.round(pagesRangeLength / 2);
  let pagesRangeMin = page - pagesRangeLengthHalf;
  let pagesRangeMax = page + pagesRangeLengthHalf;
  if (pagesRangeMin < 0) {
    pagesRangeMax = Math.min(pagesCount - 1, pagesRangeMax - pagesRangeMin);
    pagesRangeMin = 0;
  }
  if (pagesRangeMax > pagesCount - 1) {
    pagesRangeMin = Math.max(0, pagesRangeMin - (pagesRangeMax - (pagesCount - 1)));
    pagesRangeMax = pagesCount - 1;
  }

  // Generate pages shortcuts sequence
  const pagesRangeItems = [];
  for (let i = pagesRangeMin; i <= pagesRangeMax; i += 1) {
    pagesRangeItems.push(i);
  }

  return (
    <Wrapper>
      <Buttons>
        <Button onClick={() => setPage(0)} disabled={page <= 0}>
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </Button>
        <Button onClick={() => setPage(page - 1)} disabled={page <= 0}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button>

        {pagesRangeItems.map((pagesRangeItem) => (
          <ButtonNumeric
            key={pagesRangeItem}
            onClick={() => setPage(pagesRangeItem)}
            $current={page === pagesRangeItem}
          >
            <span>{pagesRangeItem + 1}</span>
          </ButtonNumeric>
        ))}

        <Button onClick={() => setPage(page + 1)} disabled={page >= pagesCount - 1}>
          <FontAwesomeIcon icon={faAngleRight} />
        </Button>
        <Button onClick={() => setPage(pagesCount - 1)} disabled={page >= pagesCount - 1}>
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </Button>
      </Buttons>
      <Breaker />
      <Description>{description}</Description>
    </Wrapper>
  );
};
export default PageChooser;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  background-color: #fafafa;
  color: #454545;
  border: solid 1px #e6e6e6;
`;
const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem;
`;
const Breaker = styled.div`
  flex: auto;
  @media (max-width: 640px) {
    flex-basis: 100%;
  }
`;
const Description = styled.div`
  margin: 1rem;
`;
const Button = styled.button.attrs(() => ({
  type: "button",
}))`
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: none;
  border: none;

  /* Removes blue border after click */
  outline: none !important;

  cursor: pointer;
`;
const ButtonNumeric = styled(Button)<{
  $current: boolean;
}>`
  ${(props) =>
    props.$current
      ? `
        color: white;
        background-color: #cccccc;
      `
      : `
        color: ${props.theme.secondary};
        @media (max-width: 640px) {
          display: none;
        }
  `}
`;
