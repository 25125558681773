import { Environment } from "@rtbhouse-apps/commonlib";
import { config, fetchConfig as fetchCfg } from "@rtbhouse-apps/commonlib/dist/config-repo";

type Config = {
  environment: Environment;
  apiHost?: string;
  apiOnSameHost?: boolean;
  version: string;
  guiLoggerEnabled: boolean;
  guiLoggerBaseUrl: string;
  guiLoggerDebugEnabled: boolean;
  guiLoggerDryRunEnabled: boolean;
  sentryEnabled: boolean;
  sentryDsn: string;
};

export default config as Config;

export async function fetchConfig(): Promise<Config> {
  return (await fetchCfg("/config.json")) as Config;
}
