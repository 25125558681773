export function mergeUrlSearchParams(...items: URLSearchParams[]): URLSearchParams {
  const search = new URLSearchParams();
  items.forEach((item) => {
    for (const [key, value] of item.entries()) {
      search.append(key, value);
    }
  });
  return search;
}

export function parseBooleanParameter(value: string): boolean {
  // Like in https://fastapi.tiangolo.com/tutorial/query-params/
  return ["1", "true", "on", "yes"].includes(value.toLowerCase());
}
export function serializeBooleanParameter(value: boolean): string {
  return value ? "true" : "false";
}

export function extractPrefixedParameters(
  search: URLSearchParams,
  prefix: string,
): { [key: string]: string } | undefined {
  let any = false;
  const parameters: { [key: string]: string } = {};
  for (const [key, value] of search.entries()) {
    if (!key.startsWith(prefix)) {
      continue;
    }
    const keyStripped = key.substr(prefix.length);
    parameters[keyStripped] = value;
    any = true;
  }
  if (!any) {
    return undefined;
  }
  return parameters;
}
