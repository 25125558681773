import React from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 1rem;

  h1 {
    font-size: 2rem;
  }

  p {
    margin-top: 10px;
  }
`;

const ErrorBoundary: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <ReactErrorBoundary
      fallbackRender={() => (
        <Wrapper>
          <h1>Whoops!</h1>
          <p>Looks like something went wrong.</p>
        </Wrapper>
      )}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
