import { useLogNavigation } from "@rtbhouse-apps/logger-react";
import React, { useState } from "react";
import styled from "styled-components";

import CommonParametersSizesForm from "components/common/CommonParametersSizesForm";
import Button from "components/forms/Button";
import Text from "components/forms/Text";
import Textarea from "components/forms/Textarea";
import useDocumentTitle from "hooks/document-title";
import { CommonParameters, Size } from "repo/CreativeData";

const Index: React.FC<{
  redirectToHashes: (hashes: string[], commonParameters: CommonParameters, sizes: Size[] | undefined) => void;
  redirectToPackIdentifier: (
    advertiserHash: string,
    packIdentifiers: string[],
    commonParameters: CommonParameters,
    sizes: Size[] | undefined,
  ) => void;
}> = (props) => {
  const { redirectToHashes, redirectToPackIdentifier } = props;

  useDocumentTitle("");
  useLogNavigation("start page");

  // By hashes
  const [hashesString, setHashesString] = useState("");
  const [hashesCommonParameters, setHashesCommonParameters] = useState<CommonParameters>({});
  const [hashesSizes, setHashesSizes] = useState<Size[] | undefined>();
  const submitByHashes = () => {
    const hashes = hashesString
      .split(/\s+/)
      .map((hash: string) => hash.trim())
      .filter((hash: string) => !!hash);
    redirectToHashes(hashes, hashesCommonParameters, hashesSizes);
  };

  // By pack identifier
  const [advertiserHash, setAdvertiserHash] = useState("");
  const [packIdentifiers, setPackIdentifiers] = useState("");
  const [packIdentifierSizes, setPackIdentifierSizes] = useState<Size[] | undefined>();
  const [packIdentifierCommonParameters, setPackIdentifierCommonParameters] = useState<CommonParameters>({});
  const submitPackIdentifier = () => {
    const advertiserHashSanitized = advertiserHash.trim();
    const packIdentifiersSanitized = packIdentifiers
      .split(",")
      .map((packIdentifier: string) => packIdentifier.trim())
      .filter((packIdentifier) => !!packIdentifier);

    if (advertiserHashSanitized && packIdentifiersSanitized.length > 0) {
      redirectToPackIdentifier(
        advertiserHashSanitized,
        packIdentifiersSanitized,
        packIdentifierCommonParameters,
        packIdentifierSizes,
      );
    }
  };

  return (
    <>
      <Examples>
        <Example>
          <p>Preview creatives by creative hash:</p>
          <form
            onSubmit={(e) => {
              submitByHashes();
              e.preventDefault();
            }}
          >
            <pre>/creatives/</pre>
            <InputWrapper>
              <Textarea
                rows={10}
                cols={60}
                placeholder="Hashes, whitespace separated"
                value={hashesString}
                valueChanged={(newValue) => setHashesString(newValue)}
              />
            </InputWrapper>
            <CommonParametersSizesForm
              commonParameters={hashesCommonParameters}
              setCommonParameters={(commonParameters) => setHashesCommonParameters(commonParameters)}
              sizes={hashesSizes}
              setSizes={setHashesSizes}
            />
            <SubmitButton>PREVIEW</SubmitButton>
          </form>
        </Example>
        <Example>
          <p>Preview creatives by pack identifier:</p>
          <form
            onSubmit={(e) => {
              submitPackIdentifier();
              e.preventDefault();
            }}
          >
            <pre>/packs/</pre>
            <InputWrapper>
              <Text
                placeholder="Advertiser Hash"
                value={advertiserHash}
                valueChanged={(newValue) => setAdvertiserHash(newValue)}
              />
            </InputWrapper>
            <pre>/</pre>
            <InputWrapper>
              <Textarea
                rows={5}
                cols={60}
                placeholder="Pack Identifiers, whitespace separated"
                value={packIdentifiers}
                valueChanged={(newValue) => setPackIdentifiers(newValue)}
              />
            </InputWrapper>
            <CommonParametersSizesForm
              commonParameters={packIdentifierCommonParameters}
              setCommonParameters={(commonParameters) => setPackIdentifierCommonParameters(commonParameters)}
              sizes={packIdentifierSizes}
              setSizes={setPackIdentifierSizes}
            />
            <SubmitButton>PREVIEW</SubmitButton>
          </form>
        </Example>
      </Examples>
    </>
  );
};
export default Index;

const Examples = styled.div`
  text-align: center;
`;
const Example = styled.div`
  padding: 0.5rem;
  margin: 0.5rem;
  background-color: #eeeeee;

  > p {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
  > form {
    display: flex;
    align-items: center;
    justify-content: center;

    > pre {
      display: inline;
    }
  }
`;
const InputWrapper = styled.div`
  display: inline-block;
  margin: 0.5rem;
`;
const SubmitButton = styled(Button).attrs({
  type: "submit",
})`
  margin-left: 1rem;
`;
