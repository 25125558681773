import React, { useState } from "react";
import styled from "styled-components";

import ClipboardButton from "./ClipboardButton";
import Loader from "./Loader";

export const CreativePreview: React.FC<{
  hash: string;
  hashShow: boolean;
  width: number;
  height: number;
  previewUrl: string;
  details?: { [key: string]: React.ReactNode };
}> = (props) => {
  const { hash, hashShow, width, height, previewUrl, details } = props;

  const [isLoading, setIsLoading] = useState(true);

  return (
    <div>
      <Details>
        {hashShow ? (
          <Detail>
            <Key>Hash: </Key>
            <Value>
              {hash} <ClipboardButton value={hash} />
            </Value>
          </Detail>
        ) : null}
        {Object.entries(details || {}).map(([key, value]) => (
          <Detail key={key}>
            <Key>{key}: </Key>
            <Value>{value}</Value>
          </Detail>
        ))}
      </Details>

      <PreviewWrapper>
        <StyledIframe
          $isLoading={isLoading}
          width={width}
          height={height}
          src={previewUrl}
          loading="lazy"
          title={`${hash} Preview`}
          frameBorder={0}
          scrolling="no"
          onLoad={() => setIsLoading(false)}
        />

        {isLoading && (
          <IframeLoaderWrapper width={width} height={height}>
            <Loader />
          </IframeLoaderWrapper>
        )}
      </PreviewWrapper>
    </div>
  );
};
export default CreativePreview;

const Details = styled.div`
  margin-bottom: 1rem;
  color: #656565;
`;

const Detail = styled.div`
  display: inline-block;
`;

const Key = styled.span`
  text-transform: uppercase;
  font-weight: bolder;
  padding-right: 0.25rem;
`;

const Value = styled.span`
  margin-right: 1rem;
`;

const PreviewWrapper = styled.div`
  position: relative;
`;

const StyledIframe = styled.iframe<{ $isLoading: boolean }>`
  opacity: ${(props) => (props.$isLoading ? 0.5 : 1)};
`;

const IframeLoaderWrapper = styled.div<{ width: number; height: number }>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;

  position: absolute;
  left: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  border: solid 1px #e6e6e6;
  overflow: hidden;
`;
