import { useLogNavigation } from "@rtbhouse-apps/logger-react";
import React, { useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import useDeepCompareEffect from "use-deep-compare-effect";

import Creatives, { CommonLocalParametersSizes } from "components/common/Creatives";
import Loader from "components/common/Loader";
import useDocumentTitle from "hooks/document-title";
import { Creative, getCreatives } from "repo/CreativeData";

const CreativesByHashes: React.FC<{
  hashes: string[];
  commonLocalParametersSizes: CommonLocalParametersSizes;
  updateCommonLocalParametersSizes: (newCommonLocalParametersSizes: CommonLocalParametersSizes) => void;
}> = (props) => {
  const { hashes, commonLocalParametersSizes, updateCommonLocalParametersSizes } = props;

  useDocumentTitle("Creatives by hashes");
  useLogNavigation("creatives by hashes");

  const [initialCreatives, setInitialCreatives] = useState<Creative[] | null>(null);
  const { showBoundary } = useErrorBoundary();

  useDeepCompareEffect(() => {
    setInitialCreatives(null);
    getCreatives(hashes, commonLocalParametersSizes.commonParameters, commonLocalParametersSizes.sizes, {}).then(
      (newCreatives) => setInitialCreatives(newCreatives),
      showBoundary,
    );
  }, [hashes, commonLocalParametersSizes.commonParameters, commonLocalParametersSizes.sizes]);

  if (initialCreatives === null) {
    return <Loader />;
  }

  return (
    <Creatives
      initialCreatives={initialCreatives}
      commonLocalParametersSizes={commonLocalParametersSizes}
      updateCommonLocalParametersSizes={updateCommonLocalParametersSizes}
    />
  );
};
export default CreativesByHashes;
