/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState } from "react";
import styled from "styled-components";

import PageChooser from "./PageChooser";

// TODO: Make this generic
const ListView: React.FC<{
  dataItems: any[];
  keyResolver: (dataItem: any) => string | number;
  componentRenderer: (dataItem: any) => React.ReactElement;
  pageSize: number;
}> = (props) => {
  const { dataItems, componentRenderer, keyResolver, pageSize } = props;

  const [page, setPage] = useState(0);
  const setPageCallback = (newPage: number) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  const pagesCount = Math.max(1, Math.ceil(dataItems.length / pageSize));

  if (page >= pagesCount) {
    setPage(0);
    return null;
  }

  const itemsFrom = page * pageSize; // Inclusive
  const itemsTo = Math.min(dataItems.length, (page + 1) * pageSize); // Exclusive

  const items = dataItems.slice(itemsFrom, itemsTo).map((dataItem) => ({
    component: componentRenderer(dataItem),
    key: keyResolver(dataItem),
  }));

  return (
    <Wrapper>
      <Items>
        {items.map(({ component, key }) => (
          <Item key={key}>{component}</Item>
        ))}
      </Items>
      <PageChooser
        page={page}
        setPage={setPageCallback}
        pagesCount={pagesCount}
        description={
          <span>
            Showing&nbsp;
            {itemsFrom + 1}
            &nbsp;-&nbsp;
            {itemsTo}
            &nbsp;of&nbsp;
            {dataItems.length}
            &nbsp;items
          </span>
        }
      />
    </Wrapper>
  );
};
export default ListView;

const Wrapper = styled.div``;
const Items = styled.div``;
const Item = styled.div`
  margin-bottom: 2rem;
  border-left: solid 3px ${(props) => props.theme.listViewBorderColor};
  padding-left: 1rem;
`;
